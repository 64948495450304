
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import ConnectorDetails from "@/components/connectors/ConnectorDetails"
import { mapStores } from "pinia"
import { useConnectorStore } from "@/stores/connector"
import { useAccountStore } from "@/stores/account"
import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  components: {
    ConnectorDetails,
  },
  data() {
    return {
      aconexCode: "",
    }
  },
  computed: {
    ...mapStores(useConnectorStore, useAccountStore),
    connexionLink() {
      const redirectUri = `${window.location.origin}/v2/users/integrations`

      return `${this.$config.public.aconexAuthUrl}/auth/authorize?response_type=code&client_id=${this.$config.public.evercamAconexClientAppId}&redirect_uri=${redirectUri}`
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      if (this.$route.query?.error) {
        const errors = {
          NO_UNIQUE_USER_FOUND:
            "Provided email doesn’t exist or there are multiple users found for specified email",
          SO_IS_DISABLED: "Authentication is disabled on the selected hostname",
          "Error 400 Invalid redirect_uri": "Error 400 Invalid redirect_uri",
          "Error 400 Unknown or invalid client_id":
            "Error 400 Unknown or invalid client_id",
        }
        const errorMessage =
          errors[this.$route.query?.error] || "Failed to login to acconex"
        this.$notifications.error({
          text: errorMessage,
          error: new Error(errorMessage),
        })

        return
      }

      if (this.$route.query?.code) {
        this.aconexCode = this.$route.query?.code
        this.connectWithAconex()
        history.replaceState({}, null, document.location.href.split(/[?#]/g)[0])
      }
    },
    async connectWithAconex() {
      try {
        await EvercamApi.aconex.authenticate({
          code: this.aconexCode,
        })
        await this.connectorStore.getConnectors()
        this.$notifications.success(
          this.$t("content.connectors.aconex_add_success")
        )
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.connectors.aconex_add_error"),
          error,
        })
      }
    },

    onConnectLinkClick() {
      this.$analytics.saveEvent(AnalyticsEvent.connectorsConnectToAconex)
    },
  },
}
